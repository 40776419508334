<template>
  <router-view/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import axios from 'axios'
export default {
  data () {
    return {
      scrolled: false
    }
  },
  mounted () {
    this.setCountry('ZA')
    window.addEventListener('resize', this.onResize)
    this.onResize()
    this.getAllUserInfo()
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    showMenu () {
      if (this.isMobile && this.showMenu) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'showMenu',
      'showMenuHolder',
      'showMenuContent',
      'isMobile',
      'userProfile',
      'flusses',
      'myDevices',
      'user'
    ])
    // theUserID () {
    //   if (this.user) {
    //     return this.user.uid
    //   } else {
    //     return null
    //   }
    // }
  },
  methods: {
    ...mapActions([
      'updateMobile',
      'setMenuStatus',
      'setCountry',
      'getPlanTypes',
      'getUser'
      // 'signInWithToken'
    ]),
    onResize () {
      if (window.innerWidth < 992) {
        this.updateMobile(true)
        this.setMenuStatus(false)
      } else {
        this.updateMobile(false)
        this.setMenuStatus(true)
      }
    },
    // getIP () {
    //   const options = {
    //     method: 'GET',
    //     url: 'https://telize-v1.p.rapidapi.com/geoip',
    //     headers: {
    //       'X-RapidAPI-Key': '5aa6b82ef1msh4a15801db2cdd50p19dd52jsne92b4f53f37e',
    //       'X-RapidAPI-Host': 'telize-v1.p.rapidapi.com'
    //     }
    //   }
    //   axios.request(options).then((response) => {
    //     const country = response.data.country_code
    //     if (country) {
    //       this.setCountry(country)
    //     }
    //   }).catch(function (error) {
    //     console.error(error)
    //   })
    // },
    getAllUserInfo () {
      if (this.user) {
        this.getPlanTypes()
        this.getUser(this.user.uid)
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.25rem !important;
}
.topBar {
  height: 64px;
}
.sideMenu-width {
  width: 230px;
}
.pTop {
  padding-top: 64px;
}
.pTopMore {
  padding-top: 50px;
}
.pSide {
  padding-left: 0;
}
.pointer {
  cursor: pointer;
}
.btn-white {
  background-color: #fff !important;
  color: #1a78b4 !important;
  border-color: #fff !important;
}
.btn-white:hover {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
}
.form-control {
  background-color: transparent !important;
}
.form-control:focus {
  border-color: var(--bs-primary) !important;
  outline: 0;
  box-shadow: none !important;
}
.input-group-text {
  color: #fff !important;
  background-color: var(--bs-primary) !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.rotateDown {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.2s ease-in-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.rotateUp {
   -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.2s ease-in-out;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.bi {
  display: flex;
}
/* .bi {
  display: inline-flex;
  line-height: 1;
  padding-top: 0.25rem;
}
.rotateDown .bi {
  padding-top: 0;
}
.rotateUp .bi {
  padding-top: 0.25rem;
} */
@media (min-width: 992px) {
  .pSide {
    padding-left: 230px;
  }
  .hover:hover {
    background-color: rgba(var(--bs-primary-rgb), 0.1);
  }
}
</style>
