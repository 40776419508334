import { getBillingPlanTypes } from '../api'

const state = {
  billingPlanTypes: null
}
const mutations = {
  SET_PLANTYPES (state, plans) {
    state.billingPlanTypes = plans
  },
  RESET_PLANTYPES (state) {
    state.billingPlanTypes = null
  }
}
const actions = {
  async getPlanTypes ({ commit }) {
    console.log('getPlansTYPES')
    await commit('RESET_PLANTYPES')
    const ret = await getBillingPlanTypes()
    console.log('getPlansTYPES RET', ret)
    if (ret.data) {
      await commit('SET_PLANTYPES', ret.data)
    }
    return true
  }
  // async getPlan ({ _ }, planId) {
  //   console.log('getPlan')
  //   const ret = await getBillingPlan(planId)
  //   console.log('getPlan RET', ret)
  //   if (ret.data) {
  //     return ret.data
  //   } else {
  //     return 'No Plan'
  //   }
  // }
}
const getters = {
  billingPlanTypes: state => state.billingPlanTypes
}

export default {
  state,
  mutations,
  actions,
  getters
}
