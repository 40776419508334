const state = {
  showMenu: true,
  showMenuHolder: true,
  showMenuContent: true,
  isMobile: false,
  showGroups: false,
  showDevices: false,
  showAccounts: false,
  showLocations: false,
  showPopup: false
}
const mutations = {
  TOGGLE_MENU (state) {
    if (state.showMenu) {
      state.showMenuContent = !state.showMenuContent
      state.showMenuHolder = !state.showMenuHolder
      setTimeout(() => {
        state.showMenu = !state.showMenu
      }, 300)
    } else {
      state.showMenu = !state.showMenu
      setTimeout(() => {
        state.showMenuContent = !state.showMenuContent
        state.showMenuHolder = !state.showMenuHolder
      }, 100)
    }
  },
  SET_MENU (state, status) {
    state.showMenu = status
    state.showMenuHolder = status
    state.showMenuContent = status
  },
  SET_MOBILE (state, status) {
    state.isMobile = status
  },
  TOGGLE_DEVICES (state) {
    state.showDevices = !state.showDevices
  },
  CLOSE_DEVICES  (state) {
    state.showDevices = false
  },
  TOGGLE_ACCOUNTS (state) {
    state.showAccounts = !state.showAccounts
  },
  CLOSE_ACCOUNTS  (state) {
    state.showAccounts = false
  },
  TOGGLE_LOCATIONS (state) {
    state.showLocations = !state.showLocations
  },
  CLOSE_LOCATIONS  (state) {
    state.showLocations = false
  },
  TOGGLE_GROUPS (state) {
    state.showGroups = !state.showGroups
  },
  CLOSE_GROUPS  (state) {
    state.showGroups = false
  },
  TOGGLE_POPUP (state, status) {
    state.showPopup = status
  }
}
const actions = {
  toggleMenuStatus ({ commit }) {
    commit('TOGGLE_MENU')
  },
  setMenuStatus ({ commit }, status) {
    commit('SET_MENU', status)
  },
  updateMobile ({ commit }, status) {
    commit('SET_MOBILE', status)
  },
  toggleShowDevices ({ commit }) {
    commit('TOGGLE_DEVICES')
  },
  closeShowDevices ({ commit }) {
    commit('CLOSE_DEVICES')
  },
  toggleShowAccounts ({ commit }) {
    commit('TOGGLE_ACCOUNTS')
  },
  closeShowAccounts ({ commit }) {
    commit('CLOSE_ACCOUNTS')
  },
  toggleShowGroups ({ commit }) {
    commit('TOGGLE_GROUPS')
  },
  closeShowGroups ({ commit }) {
    commit('CLOSE_GROUPS')
  },
  toggleShowLocations ({ commit }) {
    commit('TOGGLE_LOCATIONS')
  },
  closeShowLocations ({ commit }) {
    commit('CLOSE_LOCATIONS')
  },
  togglePopup ({ commit }, status) {
    commit('TOGGLE_POPUP', status)
  }
}
const getters = {
  showMenu: state => state.showMenu,
  showMenuHolder: state => state.showMenuHolder,
  showMenuContent: state => state.showMenuContent,
  isMobile: state => state.isMobile,
  showGroups: state => state.showGroups,
  showDevices: state => state.showDevices,
  showAccounts: state => state.showAccounts,
  showLocations: state => state.showLocations,
  showPopup: state => state.showPopup
}

export default {
  state,
  mutations,
  actions,
  getters
}
