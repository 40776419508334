import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../pages/DashboardPage.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../pages/UsersPage.vue')
  },
  {
    path: '/editperson/:deviceId/:inviteId',
    name: 'Edit User',
    component: () => import(/* webpackChunkName: "users" */ '../pages/EditUserPage.vue')
  },
  {
    path: '/inviteperson',
    name: 'Invite User',
    component: () => import(/* webpackChunkName: "users" */ '../pages/InviteUserPage.vue')
  },
  {
    path: '/invitepeople',
    name: 'Invite Users',
    component: () => import(/* webpackChunkName: "users" */ '../pages/InviteUsersPage.vue')
  },
  {
    path: '/logs',
    name: 'Access Logs',
    component: () => import(/* webpackChunkName: "logs" */ '../pages/LogsPage.vue')
  },
  {
    path: '/flusses',
    name: 'Flusses',
    component: () => import(/* webpackChunkName: "flusses" */ '../pages/FlussesPage.vue')
  },
  {
    path: '/managefluss/:deviceId',
    name: 'Manage Fluss',
    component: () => import(/* webpackChunkName: "flusses" */ '../pages/ManageFlussPage.vue'),
    props: route => ({ query: route.query })
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/ProfilePage.vue')
  },
  {
    path: '/billing/accounts',
    name: 'Accounts',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/Billing/AccountsPage.vue')
  },
  {
    path: '/billing/addaccount',
    name: 'Add Account',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/Billing/AddAccountPage.vue')
  },
  {
    path: '/billing/editaccount/:accountId',
    name: 'Edit Account',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/Billing/EditAccountPage.vue')
  },
  {
    path: '/billing/plans',
    name: 'Plans',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/Billing/PlansPage.vue')
  },
  {
    path: '/billing/manageplan/:planId',
    name: 'Manage Plan',
    component: () => import(/* webpackChunkName: "flusses" */ '../pages/Billing/ManagePlanPage.vue'),
    props: route => ({ query: route.query })
  },
  {
    path: '/billing/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/Billing/InvoicesPage.vue')
  },
  {
    path: '/billing/viewinvoice/:invoiceId',
    name: 'View Invoice',
    component: () => import(/* webpackChunkName: "flusses" */ '../pages/Billing/InvoicePage.vue')
  },
  {
    path: '/billing/setupbilling/:deviceId',
    name: 'Setup Billing',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/Billing/SetupFlussBilling.vue'),
    props: route => ({ query: route.query })
  },
  {
    path: '/adminusers',
    name: 'Admin Users',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/AdminUsersPage.vue')
  },
  {
    path: '/login',
    name: 'Auth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '../views/AuthView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async (to, from) => {
  if (!store.state.user.user && to.name !== 'Auth' && to.name !== 'Setup Billing' && to.name !== 'Manage Fluss' && to.name !== 'Manage Plan') {
    return { name: 'Auth' }
  } else if (!store.state.user.user && (to.name === 'Setup Billing' || to.name === 'Manage Fluss' || to.name === 'Manage Plan') && Object.keys(to.query).length < 1) {
    return { name: 'Auth' }
  } else if (!store.state.user.user && (to.name === 'Setup Billing' || to.name === 'Manage Fluss' || to.name === 'Manage Plan') && Object.keys(to.query).length > 0) {
    console.log('')
  } else if (store.state.user.user && to.name === 'Auth') {
    return { name: 'Dashboard' }
  } else if (store.state.user.user) {
    console.log('')
  }
})

router.beforeResolve(async to => {
  window.scrollTo(0, 0)
})

export default router
