import { getBillingAccount, setBillingAccount } from '../api'

const state = {
  myBillingAccounts: [],
  selectedAccounts: [],
  selectedBillingAccount: []
}
const mutations = {
  RESETACCOUNTS (state) {
    state.myBillingAccounts = []
  },
  SET_ACCOUNTS (state, account) {
    if (state.myBillingAccounts.length > 0) {
      const isEqual = (acc) => Object.keys(acc)[0] === Object.keys(account)[0]
      const index = state.myBillingAccounts.findIndex(isEqual)
      if (index > -1) {
        state.myBillingAccounts[index] = account
      } else {
        state.myBillingAccounts.push(account)
      }
    } else {
      state.myBillingAccounts.push(account)
    }
  },
  SET_SELECTED_ACCOUNTS (state, val) {
    const index = state.selectedAccounts.indexOf(val)
    if (index >= 0) {
      state.selectedAccounts.splice(index, 1)
    } else {
      state.selectedAccounts.push(val)
    }
  },
  SELECT_ALL_ACCOUNTS (state, val) {
    state.selectedAccounts = val
  },
  DESELECT_ALL_ACCOUNTS (state) {
    state.selectedAccounts = []
  },
  SET_SELECTED_BILLING_ACCOUNTS (state, val) {
    const index = state.selectedBillingAccount.indexOf(val)
    if (index === -1) {
      state.selectedBillingAccount = []
      state.selectedBillingAccount.push(val)
    }
  }
}
const actions = {
  async getBillingAccount ({ commit, dispatch }, accountId) {
    const ret = await getBillingAccount(accountId)
    if (ret.data) {
      await commit('SET_ACCOUNTS', ret.data)
      const invoices = Object.values(ret.data)[0].invoices
      if (invoices) {
        // console.log('invoices', invoices)
        Object.keys(invoices).forEach((invoice) => {
          // console.log('invoice 111', invoice)
          dispatch('getInvoice', invoice, { root: true })
        })
      }
      const plans = Object.values(ret.data)[0].plans
      if (plans) {
        // console.log('plans', plans)
        Object.keys(plans).forEach((plan) => {
          dispatch('getBillingPlan', plan, { root: true })
        })
      }
    }
  },
  async setBillingAccount ({ commit, dispatch, rootGetters }, details) {
    const ret = await setBillingAccount(details)
    if (ret.data) {
      const theKey = Object.keys(ret.data)[0]
      await commit('SET_ACCOUNTS', ret.data)
      await dispatch('getBillingAccount', theKey)
      await dispatch('getUser', rootGetters.user.uid, { root: true })
      return ret.data
    } else {
      return 'error'
    }
  },
  selectAccounts ({ commit }, val) {
    commit('SET_SELECTED_ACCOUNTS', val)
  },
  selectAllAccounts ({ commit }, val) {
    commit('SELECT_ALL_ACCOUNTS', val)
  },
  deselectAllAccounts ({ commit }) {
    commit('DESELECT_ALL_ACCOUNTS')
  },
  selectBillingAccount ({ commit }, val) {
    commit('SET_SELECTED_BILLING_ACCOUNTS', val)
  }
}
const getters = {
  myBillingAccounts: state => state.myBillingAccounts,
  selectedAccounts: state => state.selectedAccounts,
  selectedBillingAccount: state => state.selectedBillingAccount
}

export default {
  state,
  mutations,
  actions,
  getters
}
