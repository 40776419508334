
import { updateFlussSettings, getFlussSettings } from '../api'
const state = {
  flussSettings: {}
}
const mutations = {
  CLEAR_FLUSS_SETTINGS (state) {
    state.flussSettings = []
  },
  SET_FLUSS_SETTINGS (state, res) {
    state.flussSettings[res.flussId] = res.settings
  }
}
const actions = {
  async getFlussSettings ({ commit }, flussId) {
    const result = await getFlussSettings(flussId)
    let res = {
      flussId,
      settings: {
        defaultTemporaryAccess: {
          duration: 1,
          unit: 'hours',
          display: '1 hour'
        }
      }
    }

    if (result && result.data) {
      res = {
        flussId,
        settings: result.data.settings
      }
    }
    await commit('SET_FLUSS_SETTINGS', res)
  },
  async updateFlussSettings ({ dispatch }, { flussId, updates }) {
    const result = await updateFlussSettings({ flussId, updates })
    await dispatch('getFlussSettings', flussId)
    return result
  }
}
const getters = {
  flussSettings: state => state.flussSettings
}

export default {
  state,
  mutations,
  actions,
  getters
}
