import { db } from '../../config/firebase'
import { ref, child, get } from 'firebase/database'

import { updateAccess, revokeAccess, sendInvite } from '../api'

const state = {
  access: [],
  accessStatus: 'ready'
}

const mutations = {
  SET_ACCESS (state, accessNode) {
    if (state.access && state.access.length > 0) {
      if (!state.access.includes(accessNode)) {
        state.access.push(accessNode)
      }
    } else {
      state.access.push(accessNode)
    }
  },
  RESET_ACCESS (state) {
    state.access = []
  },
  SET_STATUS (state, status) {
    state.accessStatus = status
  }
}
const actions = {
  getAccessForFluss ({ commit }, flussId) {
    const dbRef = ref(db)
    return get(child(dbRef, `access/${flussId}`)).then((snapshot) => {
      if (snapshot.exists()) {
        const obj = {}
        obj[flussId] = snapshot.val()
        commit('SET_ACCESS', obj)
      } else {
        console.log('No data available')
      }
    }).catch((error) => {
      console.error(error)
    })
  },
  resetAccess ({ commit }) {
    commit('RESET_ACCESS')
  },
  async updateAccess ({ dispatch }, invite) {
    dispatch('resetAccess')
    return await updateAccess(invite)
  },
  async revokeAccess ({ dispatch }, invite) {
    try {
      await dispatch('resetAccess')
      return await revokeAccess(invite)
    } catch (err) {
      console.error(err)
      return err
    }
  },
  async sendInvite ({ dispatch }, payload) {
    return await sendInvite(payload)
  },
  setStatus ({ commit }, status) {
    commit('SET_STATUS', status)
  }
}
const getters = {
  access: state => {
    return state.access
  },
  accessStatus: state => {
    return state.accessStatus
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
