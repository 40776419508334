import { AwsClient } from 'aws4fetch'

const MY_ACCESS_KEY = 'AKIAVLSS6VEQIMUP2IVM'
const MY_SECRET_KEY = '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'
// https://developers.cloudflare.com/workers/reference/apis/environment-variables/#secrets
const aws = new AwsClient({ accessKeyId: MY_ACCESS_KEY, secretAccessKey: MY_SECRET_KEY })

// https://kp0hpjq9qi.execute-api.af-south-1.amazonaws.com/live/contactus?name=Thomas Davies&email=tom@fluss.io&message=hello tom
// https://docs.aws.amazon.com/lambda/latest/dg/API_Invoke.html
const LAMBDA_FN_API = 'https://kp0hpjq9qi.execute-api.af-south-1.amazonaws.com/live'

// async function invokeMyLambda (event) {
//   const res = await aws.fetch(`${LAMBDA_FN_API}/contactus`, { body: JSON.stringify(event) })

//   // `res` is a standard Response object: https://developer.mozilla.org/en-US/docs/Web/API/Response
//   return res.json()
// }

// invokeMyLambda({ my: 'event' }).then(json => console.log(json))

const sendContact = async (contactForm) => {
  const res = await aws.fetch(`${LAMBDA_FN_API}/contactus`, { body: JSON.stringify(contactForm) })
  return res.json()
}

export { sendContact }
