import { getBillingInvoice, setBillingInvoice, deleteBillingInvoice } from '../api'

const state = {
  myInvoices: []
}
const mutations = {
  RESET_MYINVOICES (state) {
    state.myInvoices = []
  },
  SET_MYINVOICES (state, invoice) {
    if (state.myInvoices.length > 0) {
      const isEqual = (theInvoice) => Object.keys(theInvoice)[0] === Object.keys(invoice)[0]
      const index = state.myInvoices.findIndex(isEqual)
      if (index > -1) {
        state.myInvoices[index] = invoice
      } else {
        state.myInvoices.push(invoice)
      }
    } else {
      state.myInvoices.push(invoice)
    }
  }
}
const actions = {
  async getInvoice ({ commit }, invoiceId) {
    // console.log('getInvoice invoiceId', invoiceId)
    const ret = await getBillingInvoice(invoiceId)
    // console.log('getInvoice ret', ret)
    if (ret.data) {
      await commit('SET_MYINVOICES', ret.data)
    }
  },
  async setInvoice ({ commit }, details) {
    // console.log('setInvoice DETAILS', details)
    await commit('RESET_MYINVOICES')
    return setBillingInvoice(details)
  },
  deleteInvoice ({ commit }, details) {
    // console.log('deletInvoice DETAILS', details)
    commit('RESET_MYINVOICES')
    return deleteBillingInvoice(details)
  }
}
const getters = {
  myInvoices: state => state.myInvoices
}

export default {
  state,
  mutations,
  actions,
  getters
}
