import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'

let firebaseConfig = {}
if (process.env.NODE_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyDvZjDIQfZu8nKh51vEa8SQUXQiZuNjMaU',
    authDomain: 'boomio.firebaseapp.com',
    databaseURL: 'https://boomio.firebaseio.com',
    projectId: 'project-4228988587603651556',
    storageBucket: 'project-4228988587603651556.appspot.com',
    messagingSenderId: '676724788198',
    appId: '1:676724788198:web:f98a5be68446e0ad33e427',
    measurementId: 'G-ZEKF8QCQEP'
  }
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyCoNUrUdtqWscr0y-Ah4yg0rrsrz9v_RB8',
    authDomain: 'fluss-dev-c2208.firebaseapp.com',
    databaseURL: 'https://fluss-dev-c2208.firebaseio.com',
    projectId: 'fluss-dev-c2208',
    storageBucket: 'fluss-dev-c2208.appspot.com',
    messagingSenderId: '29016658821',
    appId: '1:29016658821:web:90ce96a2ea970237',
    measurementId: 'G-6M3DMZ9C2Q'
  }
}

// const firebaseConfig = {
//   apiKey: 'AIzaSyDvZjDIQfZu8nKh51vEa8SQUXQiZuNjMaU',
//   authDomain: 'boomio.firebaseapp.com',
//   databaseURL: 'https://boomio.firebaseio.com',
//   projectId: 'project-4228988587603651556',
//   storageBucket: 'project-4228988587603651556.appspot.com',
//   messagingSenderId: '676724788198',
//   appId: '1:676724788198:web:f98a5be68446e0ad33e427',
//   measurementId: 'G-ZEKF8QCQEP'
// }
// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const db = getDatabase(app)
export const auth = getAuth(app)
export const functions = getFunctions(app)
