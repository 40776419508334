import { createShareLink, getLink } from '../api'

const actions = {
  createShareLink ({ dispatch }, invite) {
    console.log('create share link', invite)
    return createShareLink(invite)
  },
  getLinkDetails ({ getters }, link) {
    getLink({ link, name: getters.user.name, mobile: getters.user.mobile }).then((result) => {
      console.log(result)
    })
  }
}
export default {
  actions
}
