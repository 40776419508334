import { getBillingPlan, setBillingPlan, getFlussPlan, customPlanRequest } from '../api'
import { sendContact } from '../contactApi'

const state = {
  myBillingPlans: []
}
const mutations = {
  RESET_MYPLANS (state) {
    state.myBillingPlans = []
  },
  SET_MYPLANS (state, plan) {
    if (state.myBillingPlans.length > 0) {
      const isEqual = (thePlan) => Object.keys(thePlan)[0] === Object.keys(plan)[0]
      const index = state.myBillingPlans.findIndex(isEqual)
      if (index > -1) {
        state.myBillingPlans[index] = plan
      } else {
        state.myBillingPlans.push(plan)
      }
    } else {
      state.myBillingPlans.push(plan)
    }
  }
}
const actions = {
  async getFlussPlan ({ _ }, flussId) {
    const ret = await getFlussPlan(flussId)
    if (ret.data) {
      return ret.data.plans
    }
  },
  async getBillingPlan ({ commit, dispatch }, planId) {
    const ret = await getBillingPlan(planId)
    if (ret.data) {
      await commit('SET_MYPLANS', ret.data)
    }
  },
  async setBilling ({ _ }, details) {
    await setBillingPlan(details)
  },
  async setCustomPlan ({ rootGetters }, details) {
    const customPlanDetails = {
      mobile: rootGetters.user.phoneNumber,
      flussId: details.data.flussId,
      planId: details.data.planId,
      accountId: details.data.billingAccountId,
      userId: rootGetters.user.uid,
      name: rootGetters.userProfile.name,
      email: rootGetters.userProfile.email
    }
    console.log('customPlanDetails', customPlanDetails)
    const ret = await customPlanRequest(customPlanDetails)
    console.log(ret)
    const theMessage = `I want a custom plan \n\n Phone Number: ${rootGetters.user.phoneNumber} \n\n Details \n\n FlussId: ${details.data.flussId} \n PlanId: ${details.data.planId} \n UserId: ${rootGetters.user.uid}`
    const form = {
      name: rootGetters.userProfile.name,
      message: theMessage,
      email: rootGetters.userProfile.email
    }
    return await sendContact(form)
  }
}
const getters = {
  myBillingPlans: state => state.myBillingPlans
}

export default {
  state,
  mutations,
  actions,
  getters
}
