import { auth } from '../../config/firebase'
import router from '../../router'
// import { auth, db } from '../../config/firebase'
import { signOut } from 'firebase/auth'
// import { ref, child, get } from 'firebase/database'

import { isFlussUser, updateUser, deleteAccount, getUser, test, testInvoice, testCard, logout } from '../api'

const state = {
  user: null,
  userProfile: {},
  selectedDevices: [],
  selectedGroups: [],
  selectedLocations: [],
  country: null
}

const mutations = {
  SET_USER (state, user) {
    state.user = user
  },
  SET_USER_PROFILE (state, userProfile) {
    // console.log('SET_USER_PROFILE', userProfile)
    state.userProfile = userProfile
  },
  SET_SELECTED_DEVICES (state, val) {
    const index = state.selectedDevices.indexOf(val)
    if (index >= 0) {
      state.selectedDevices.splice(index, 1)
    } else {
      state.selectedDevices.push(val)
    }
  },
  SELECT_ALL_DEVICES (state, val) {
    state.selectedDevices = val
  },
  DESELECT_ALL_DEVICES (state) {
    state.selectedDevices = []
  },
  SET_SELECTED_GROUPS (state, val) {
    const index = state.selectedGroups.indexOf(val)
    if (index >= 0) {
      state.selectedGroups.splice(index, 1)
    } else {
      state.selectedGroups.push(val)
    }
  },
  SELECT_ALL_GROUPS (state, val) {
    state.selectedGroups = val
  },
  DESELECT_ALL_GROUPS (state) {
    state.selectedGroups = []
  },
  SET_SELECTED_LOCATIONS (state, val) {
    const index = state.selectedLocations.indexOf(val)
    if (index >= 0) {
      state.selectedLocations.splice(index, 1)
    } else {
      state.selectedLocations.push(val)
    }
  },
  SELECT_ALL_LOCATIONS (state, val) {
    state.selectedLocations = val
  },
  DESELECT_ALL_LOCATIONS (state) {
    state.selectedLocations = []
  },
  SET_COUNTRY (state, country) {
    state.country = country
  }
}

const actions = {
  logout ({ commit }) {
    signOut(auth).then(() => {
      commit('SET_USER', null)
      commit('SET_USER_PROFILE', {})
      router.push('/login')
    })
  },
  async logoutEverywhere ({ dispatch }) {
    try {
      await logout()
      await dispatch('logout')
    } catch (err) {
      console.error(err)
    }
  },
  async getUser ({ commit, dispatch }, userId) {
    // console.log('getUser ', userId)
    const ret = await getUser(userId)
    if (ret.data) {
      // console.log('getUser RET', ret.data)
      await commit('SET_USER_PROFILE', ret.data)
    }
    return ret.data
  },
  async setUserDetails ({ commit, dispatch }) {
    // console.log('setUserDetails')
    const arr = []
    if (state.userProfile && state.userProfile.flusse) {
      for (const [key, value] of Object.entries(state.userProfile.flusse)) {
        if (value.owner === 'Fluss admin') {
          await arr.push({ id: key, ...value })
        } else if (value.userType === 'user') {
          await arr.push({ id: key, ...value })
        }
      }
    }
    // console.log('setUserDetails Arr', arr)
    if (arr.length > 0) {
      await dispatch('selectDevices', arr[0].id)
    }
    if (state.userProfile && state.userProfile.billingAccounts) {
      Object.keys(state.userProfile.billingAccounts).forEach((account) => {
        dispatch('getBillingAccount', account, { root: true })
        dispatch('selectAccounts', account, { root: true })
      })
    }
  },
  async setUser ({ dispatch, commit }, user) {
    await commit('SET_USER', user)
    await dispatch('getUser', user.uid)
    await dispatch('setUserDetails')
    // dispatch('initPushNotifications')
    // dispatch('getLatestFirmwareVersion')
  },
  setCountry ({ commit }, country) {
    commit('SET_COUNTRY', country)
  },
  async deleteAccount ({ dispatch }) {
    try {
      await deleteAccount()
      dispatch('logout')
    } catch (err) {
      console.error(err)
    }
  },
  async updateUserProfile ({ dispatch, state }, updates) {
    // console.log('updating user', updates)
    const ret = await updateUser(updates)
    // console.log('updateUserProfile ret', ret, state.user.uid)
    if (ret) {
      await dispatch('getUser', state.user.uid)
    }
    return ret
  },
  async isFlussUser ({ _ }, number) {
    console.log('NUMBER', number)
    return await isFlussUser(number)
  },
  selectDevices ({ commit }, val) {
    commit('SET_SELECTED_DEVICES', val)
  },
  selectAllDevices ({ commit }, val) {
    commit('SELECT_ALL_DEVICES', val)
  },
  deselectAllDevices ({ commit }) {
    commit('DESELECT_ALL_DEVICES')
  },
  selectGroups ({ commit }, val) {
    commit('SET_SELECTED_GROUPS', val)
  },
  selectAllGroups ({ commit }, val) {
    commit('SELECT_ALL_GROUPS', val)
  },
  deselectAllGroups ({ commit }) {
    commit('DESELECT_ALL_GROUPS')
  },
  selectLocations ({ commit }, val) {
    commit('SET_SELECTED_LOCATIONS', val)
  },
  selectAllLocations ({ commit }, val) {
    commit('SELECT_ALL_LOCATIONS', val)
  },
  deselectAllLocations ({ commit }) {
    commit('DESELECT_ALL_LOCATIONS')
  },
  async testRun ({ _ }) {
    console.log('RUN TEST START')
    return await test()
  },
  async testCard ({ _ }) {
    console.log('RUN TESTCARD START')
    return await testCard()
  },
  async testInvoice ({ _ }) {
    console.log('RUN testInvoice START')
    return await testInvoice()
  }
  // bindUser: firebaseAction(({ bindFirebaseRef }, userId) => {
  //   // return the promise returned by `bindFirebaseRef`
  //   return bindFirebaseRef('userProfile', db.ref(`/users/${userId}`))
  // })
}

const getters = {
  user: state => {
    return state.user
  },
  userProfile: state => {
    return state.userProfile
  },
  flusses: state => {
    return state.userProfile && state.userProfile.flusse ? state.userProfile.flusse : {}
  },
  hasDevices: state => {
    return state.userProfile && state.userProfile.flusse && Object.keys(state.userProfile.flusse).length > 0
  },
  myDevices: state => {
    const arr = []
    if (state.userProfile && state.userProfile.flusse) {
      for (const [key, value] of Object.entries(state.userProfile.flusse)) {
        if (value.owner === 'Fluss admin') {
          arr.push({ id: key, ...value })
        } else if (value.userType === 'user') {
          arr.push({ id: key, ...value })
        }
      }
    }
    return arr
  },
  selectedDevices: state => state.selectedDevices,
  country: state => state.country,
  currency: state => {
    if (state.country && state.country !== 'ZA') {
      return 'USD'
    } else {
      return 'ZAR'
    }
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
