
export default {
  menu: require('./menu').default,
  user: require('./user').default,
  access: require('./access').default,
  links: require('./links').default,
  devices: require('./devices').default,
  auth: require('./auth').default,
  logs: require('./logs').default,
  plans: require('./plans').default,
  billingAccounts: require('./billingAccounts').default,
  billingPlans: require('./billingPlans').default,
  billingInvoices: require('./billingInvoices').default,
  flussSettings: require('./flussSettings').default
}
