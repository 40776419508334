
import { claimAccess, checkIfOwned, updateFluss, deleteDevice, transferFluss } from '../api'
const state = {
  checkedFlusses: {}
}
const mutations = {
  CLEAR_OWNED_RESULTS (state) {
    state.checkedFlusses = {}
  },
  SET_OWNED_RESULT (state, { flussName, result }) {
    state.checkedFlusses[flussName] = result
    console.info(state)
  },
  SET_CLAIMED (state, flussName) {
    state.checkedFlusses[flussName] = { data: { owned: true } }
  }
}
const actions = {
  async checkIfOwned ({ state, commit }, flussName) {
    console.log('checking for owner', flussName)
    if (state.checkedFlusses[flussName]) {
      return state.checkedFlusses[flussName]
    }
    const result = await checkIfOwned(flussName)
    commit('SET_OWNED_RESULT', { flussName, result })
    return result
  },
  async claimFluss ({ commit, dispatch }, fluss) {
    await dispatch('stopScan')
    console.log('claiming fluss', fluss)
    commit('SET_CLAIMED', fluss.fluss)
    commit('FLUSS_CLAIMED', fluss.fluss)
    return claimAccess(fluss)
  },
  updateFluss ({ _ }, { flussId, updates }) {
    console.log('updating fluss', flussId, updates)
    return updateFluss({ flussId, updates })
  },
  async deleteDevice ({ commit, dispatch }, flussId) {
    console.log('deleteDevice', flussId)
    await deleteDevice(flussId)
    commit('CLEAR_OWNED_RESULTS')
    commit('FLUSS_DELETED', flussId)
    return true
  },
  async transferFluss ({ commit, getters }, { flussId, toUserId }) {
    const theFluss = getters.flusses[flussId]
    console.log('transfering fluss', theFluss)
    const planId = theFluss.plan ? theFluss.plan.planId : undefined
    const maxUsers = theFluss.plan ? theFluss.plan.maxUsers : undefined
    return transferFluss({ flussId, flussName: theFluss.deviceName, toUserId, planId, maxUsers })
  }
}

export default {
  state,
  mutations,
  actions
}
