import { getAccessLogs } from '../api'

const state = {
  accessLogs: [],
  accessLogStatus: 'ready'
}
const mutations = {
  SET_LOGS (state, logs) {
    // console.log('SET_LOGS', logs, state.accessLogs)
    // console.log('state.accessLogs.length', state.accessLogs.length)
    if (state.accessLogs.length > 0) {
      logs.forEach((log) => {
        const sameDevice = (element) => element.fluss === log.fluss
        const sameTime = (element) => element.date === log.date
        if (state.accessLogs.some(sameDevice && sameTime)) {
          console.log('DUplicate')
        } else {
          state.accessLogs = state.accessLogs.concat(logs)
        }
      })
    } else {
      state.accessLogs = logs
    }
    // console.log('AFTER SET_LOGS', logs, state.accessLogs)
  },
  CLEAR_LOGS (state) {
    state.accessLogs = []
  },
  SET_LOG_STATUS (state, status) {
    state.accessLogStatus = status
  }
}
const actions = {
  async getAccessLogsForFluss ({ commit }, details) {
    // console.log('getAccessLogsForFluss', details)
    const device = {
      fluss: details.fluss
    }
    const arr = []
    const ret = await getAccessLogs(details)
    // console.log('RRET', arr, ret)
    if (ret) {
      ret.data.forEach(item => {
        if (item && Object.keys(item).length > 0) {
          const obj = { ...item, ...device }
          arr.push(obj)
        }
        // console.log('ARR in ', arr)
      })
      // console.log('ARR', arr)
      commit('SET_LOGS', arr)
    }
  },
  resetLogs ({ commit }) {
    commit('CLEAR_LOGS')
  },
  setLogStatus ({ commit }, status) {
    commit('SET_LOG_STATUS', status)
  }
}
const getters = {
  accessLogs: state => {
    return state.accessLogs
  },
  accessLogStatus: state => {
    return state.accessLogStatus
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
